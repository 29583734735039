<template>
    <v-card>
        <v-form @submit="addCategoryCheck($v.currentCategory)">
            <v-app-bar absolute text class="fixedToolbar" style="z-index: 2;">
                <v-toolbar-title class="headline grey-text">New Category</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon type="submit" v-on:click.prevent="addCategoryCheck($v.currentCategory)">
                    <v-icon color="green">fas fa-check</v-icon>
                </v-btn>
                <v-btn icon @click.native="$router.push({ path: '/dashboard/category' })">
                    <v-icon color="red">fas fa-times</v-icon>
                </v-btn>
            </v-app-bar>

            <v-card-text>

                <v-container grid-list-md class="padContentLess">
                    <v-layout wrap>
                        <v-flex xs12>
                            <v-text-field label="Category Name" v-model="currentCategory.categoryName"
                                          :error-messages="categoryNameErrors"
                                          @input="$v.currentCategory.categoryName.$touch()"
                                          @blur="$v.currentCategory.categoryName.$touch()"></v-text-field>
                        </v-flex>
                        <v-flex xs12>
                            <v-autocomplete :items="items"
                                            v-model="currentCategory.parentCategory"
                                            label="Parent Category"
                                            item-text="categoryName"
                                            item-value="categoryName">
                                <template slot="selection"
                                          slot-scope="data">
                                    <v-chip :input-value="data.selected"
                                            class="chip--select-multi">
                                        {{ data.item.categoryName }}
                                    </v-chip>
                                </template>
                                <template slot="item"
                                          slot-scope="data">

                                    <template>
                                        <v-list-tile-content>
                                            <v-list-tile-title v-html="data.item.categoryName"></v-list-tile-title>
                                            <!--<v-list-tile-sub-title v-html="data.item.currencyShort"></v-list-tile-sub-title>-->
                                        </v-list-tile-content>
                                    </template>
                                </template>
                            </v-autocomplete>
                        </v-flex>
                    </v-layout>
                </v-container>

            </v-card-text>
            </v-form>
    </v-card>

</template>

<script>
    import { validationMixin } from 'vuelidate'
    import { required } from 'vuelidate/lib/validators'

    import { globalFunctions } from '../../../javascript/globalFunctions'

    export default {
        mixins: [validationMixin],
        props: ['drawer'],
        data() {
            return {
                sameCategory: false,
                currentCategory: {
                    id: 0,
                    categoryName: '',
                    iconSize: 0,
                    iconData: '',
                    parentCategory: '',
                    userDefined: false,
                    userId: '',
                    objectState: 'New'
                },
                items: []
            }
        },
        computed: {
            categoryNameErrors() {
                const errors = []
                if (!this.$v.currentCategory.categoryName.$dirty) return errors
                this.sameCategory && errors.push('A Category already has this Name. Enter a new Name or update Categry')
                !this.$v.currentCategory.categoryName.required && errors.push('Category Name is required')
                return errors
            },
            //parentCategoryErrors() {
            //    const errors = []
            //    if (!this.$v.currentCategory.parentCategory.$dirty) return errors
            //    !this.$v.currentCategory.parentCategory.required && errors.push('Parent Category is required')
            //    return errors
            //},
        },
        methods: {
            addCategoryCheck: function ($v) {

                $v.$touch(); //Triggers Error Messages

                if ($v.categoryName.$invalid ) {
                    return
                }

                for (let i = 0; i < this.items.length; i++) {
                    if (this.items[i].categoryName.toLowerCase() === this.currentCategory.categoryName.toLowerCase()) {
                        this.sameAccount = true
                        return
                    }
                    if (i === this.items.length - 1) {
                        this.sameAccount = false
                    }
                }

                this.addCategory($v);
            },
            addCategory: function ($v) {
                let newEntry = JSON.parse(JSON.stringify(this.currentCategory))

                this.addDialog = false

                newEntry.id = globalFunctions.getNextIdForObject(this.items)

                this.$store.commit('addNewCategoryToStore', newEntry)

                this.$router.push({ path: '/dashboard/category' })
            },
        },
        beforeMount() {

            this.items = this.$store.state.Categories

            ///Remove deleted items from items array
            this.items = globalFunctions.removeDeletedObjectsFromItems(this.items)
        },
        validations: {
            currentCategory: {
                categoryName: {
                    required
                },
                //parentCategory: {
                //    required
                //}
            }
        }
    }
</script>
